import topic from '../topic';
import { getNoCatch } from '@api/request';
export const mdinAPI = {
  getMdins: topic.mdinTopic + '/get_mdins',
  getMdinById: topic.mdinTopic + '/get_mdin_by_id',
  addMdin: topic.mdinTopic + '/add_mdin',
  editMdin: topic.mdinTopic + '/edit_mdin',
  deleteMdinByIds: topic.mdinTopic + '/delete_mdin_by_ids',
  addMoldnot: params => getNoCatch(`${topic.mdinTopic}/addMoldnot`, params),
  getMdinsToMdnc: params => getNoCatch(`${topic.mdinTopic}/getMdinsToMdnc`, params)
};
