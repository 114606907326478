/**
 * 数组工具类
 * @author HuangJun
 */

/**
 * @description 根据给定的尺寸分割数组
 * @param {array} array 数组
 * @param {number} size 尺寸
 * @return [Array*] 若干数组
 */
export const uniformArray = (array, size) => {
  let result = [];
  for (let x = 0; x < Math.ceil(array.length / size); x++) {
    let start = x * size;
    let end = start + size;
    result.push(array.slice(start, end));
  }
  return result;
};

/**
 * @description 获取数组中的id
 * @param array
 * @param column
 * @return {*[]}
 */
export const getArrayIds = (array, column) => Array.from(array, item => item[column]);

/**
 * @description 删除表格行并加入标识
 * @param rowId 行id(字段id)
 * @param array 表格数据
 * @param map map对象 用于存储有id的数据 并加入flag标识
 * @param selections 选中的表格数据
 * @return {{tableData, map}} 表格数据, 已删除数据
 */
export const deleteTableRow = (rowId, array, map, selections) => {
  selections.forEach(item => {
    console.log(rowId, item[rowId]);
    // 判断是否有对应id数据
    if (item[rowId] === 0 || item[rowId]) {
      // 增加标识
      array[item.index].destroy_flag = 1;
      // map存储
      map.set(item.index, array[item.index]);
    } else {
      // 删除选中的无id数据 直接删除
      delete array[item.index];
    }
  });
  array = array.filter(x => x !== undefined && x.destroy_flag !== 1);
  return { tableData: array, map: map };
};
